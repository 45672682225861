<template>

    <div class='white-panel-items'>
        <div class='wp-row'>
            <div class='info-panel-text info-panel-text-uppercase'>
                <b v-if="$i18n.locale == 'kk'">
                    {{ docDate }} {{ docNumber }} {{ documentName }}{{ docVersion }}
                </b>
                <b v-else>
                    {{ documentName }} {{ docNumber }} {{ docDate }}{{ docVersion }}
                </b>
            </div>

            <v-common-chip 
                v-if="isCommon && (dsType === 'IncomingDocument' || dsType === 'OutgoingDocument' || dsType === 'ProtocolDocument' || dsType === 'NPADocument' || dsType === 'ReportQuery' || dsType === 'InnerDocument')"
                :document-type="dsType"
            />

            <div class='info-panel-text info-panel-status-text'>{{ documentStatusText }}</div>
        </div>
    </div>

</template>

<script>
import sys from '@/services/system'
import { mapGetters } from 'vuex';

export default
{
    name: 'DocumentPanel',
    props:
    {
        documentInfo:
        {
            type: Object,
            default: null
        }
    },
    data()
    {
        return {

        }
    },
    computed:
    {
        ...mapGetters('actionsource', { isCommon: 'isDataSourceCommon', dsType: 'getDataSourceType' }),
        incommingDocumentNameByTypeC4() {
            switch(this.documentInfo.Document.Card.TypeC4)
            {
                default:
                case 0: return this.$t('Входящий_документ');
                case 2: return this.$t('Директивный_организационно_распорядительный_документ');
                case 3: return this.$t('Пункт_к_входящему_документу');
            }
        },
        innergDocumentNameByTypeC4() {
            switch (this.documentInfo.Document.Card.TypeC4)
            {
                default:
                case 0:
                    return this.documentInfo.Document.RegState != 1
                        ? this.$t('Проект_внутреннего_документа')
                        : this.$t('Внутренний_документ');

                case 1:
                    return this.documentInfo.Document.RegState != 1
                        ? this.$t('Проект_организационно-распорядительного_документа')
                        : this.$t('Организационно-распорядительный_документ');
            }
        },
        protocolDocumentNameByTypeC4(){
            switch(this.documentInfo.Document.Card.TypeC4)
            {
                default:
                case 2: return this.$t('Протокол');
                case 3: return this.$t('Пункт_к_протоколу');
            }
        },
        euolStatementNameByPerCategory() {
            switch(this.documentInfo.Document.Card.PerCategory)
            {
                case 1: return this.$t('Обращение_физического_лица');
                case 2: return this.$t('Обращение_юридического_лица');
                default: return this.$t('Обращение');
            }
        },
        iQalaStatementDocumentName() {
            return `${this.$t('Заявка')}`;
        },
        contractDocumentName() {
            return `${this.$t('Договор')}`;
        },
        documentName()
        {
            let type;
            switch (this.documentInfo.__type)
            {
                case 'DocumentContainer:#Avrora.Objects.Modules.Docflow.Web': type = this.documentInfo.Document.__type; break;
                case 'Resolution:#Avrora.Objects.Modules.Docflow.DocflowObjects': type = 'Resolution:#Avrora.Objects.Modules.Docflow.DocflowObjects'; break;
                case 'ReportResolution:#Avrora.Objects.Modules.Docflow.DocflowObjects': type = 'ReportResolution:#Avrora.Objects.Modules.Docflow.DocflowObjects'; break;
                case 'ActItem:#Avrora.Objects.Modules.Docflow.DocflowObjects': type = 'ActItem:#Avrora.Objects.Modules.Docflow.DocflowObjects'; break;
                case 'Order:#Avrora.Objects.Modules.Docflow.DocflowObjects': type = 'Order:#Avrora.Objects.Modules.Docflow.DocflowObjects'; break;
                case 'IQalaOrder:#Avrora.Objects.Modules.Docflow.DocflowObjects': type = 'IQalaOrder:#Avrora.Objects.Modules.Docflow.DocflowObjects'; break;
                case 'NPADocument:#Avrora.Objects.Modules.Docflow.DocflowObjects': type = 'NPADocument:#Avrora.Objects.Modules.Docflow.DocflowObjects'; break;
                case 'Meeting:#Avrora.Objects.Modules.Meetings': type = 'Meeting:#Avrora.Objects.Modules.Meetings'; break;
                case 'ReportQuery:#Avrora.Objects.Modules.Docflow.DocflowObjects': type = 'ReportQuery:#Avrora.Objects.Modules.Docflow.DocflowObjects'; break;
                default: type = 'Unknown'; break;
            }

            switch (type)
            {
                case 'OutgoingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects':
                
                    if (this.documentInfo.Document.NotDraft)
                        return this.$t('Исходящий_документ');

                    return this.documentInfo.Document.RegState != 1 
                        ? this.$t('Проект_исходящего_документа')
                        : this.$t('Исходящий_документ');

                case 'IncomingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects': //нет версий                            
                    return this.incommingDocumentNameByTypeC4;                

                case 'InnerDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects':
                    return this.innergDocumentNameByTypeC4;                

                case 'EuolStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects': //нет версий
                    return this.euolStatementNameByPerCategory;
                
                case 'IQalaStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects':
                    return this.iQalaStatementDocumentName;
                    
                case 'Contract:#Avrora.Objects.Modules.Docflow.DocflowObjects.C4':
                    return this.contractDocumentName;

                case 'Resolution:#Avrora.Objects.Modules.Docflow.DocflowObjects': //нет версий //нет номера
                {
                    let docName;
                    switch(this.documentInfo.Document.__type)
                    {
                        case 'OutgoingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects': docName = this.$t('Исходящий_документ'); break;
                        case 'IncomingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects': docName =  this.incommingDocumentNameByTypeC4; break;
                        case 'InnerDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects': docName = this.innergDocumentNameByTypeC4; break;
                        case 'EuolStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects': docName = this.euolStatementNameByPerCategory; break;
                        case 'ProtocolDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects': docName = this.protocolDocumentNameByTypeC4; break;
                        default: docName = ''; break;
                    }

                    return `${this.$t('Резолюция_на')} ${docName}`;
                }

                case 'ActItem:#Avrora.Objects.Modules.Docflow.DocflowObjects': //нет версий //нет номера
                    return this.$t('Пункт_к_организационно-распорядительному_документу');
                
                case 'IQalaOrder:#Avrora.Objects.Modules.Docflow.DocflowObjects': //нет версий //нет номера
                    return `${this.$t('Наряд_на_заявку')}`;

                case 'Order:#Avrora.Objects.Modules.Docflow.DocflowObjects': //нет версий
                    return this.$t('Личное_поручение');

                case 'ReportResolution:#Avrora.Objects.Modules.Docflow.DocflowObjects': //нет версий
                    return this.$t('Задача_по_формированию_отчета');

                case 'NPADocument:#Avrora.Objects.Modules.Docflow.DocflowObjects':{
                    if (this.documentInfo.Document.RegState != 1)
                        return this.$t('Проект_Нормативно-правового_акта');
                    switch(this.documentInfo.Document.NPAType){
                        case 0:
                            return this.$t('Решение');
                        case 1:
                            return this.$t('Постановление');
                        case 2:
                            return this.$t('Распоряжение');
                        default:
                            return  this.$t('Нормативно-правовой_акт');
                    }
                }
                    
                case 'ProtocolDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects': //нет версий
                    return this.protocolDocumentNameByTypeC4;

                case 'Meeting:#Avrora.Objects.Modules.Meetings':
                    return this.documentInfo.is_new_record ? this.$t('Новое_совещание') : this.$t('Совещание')+' №' + this.documentInfo.Number;
                
                case 'ReportQuery:#Avrora.Objects.Modules.Docflow.DocflowObjects':
                    return this.documentInfo.is_new_record ? this.$t('Новый_запрос_на_формирование_отчета') : this.$t('Запрос_на_формирование_отчета');

                default:
                    return this.documentInfo.DocumentName;
            }
        },        
        docNumber()
        {
            if(!this.documentInfo.Document)
                return '';

            if (['Resolution:#Avrora.Objects.Modules.Docflow.DocflowObjects', 'ActItem:#Avrora.Objects.Modules.Docflow.DocflowObjects','ReportQuery:#Avrora.Objects.Modules.Docflow.DocflowObjects'].includes(this.documentInfo.Document.__type))
                return '';

            switch (this.documentInfo.Document.RegState) 
            {
                case 1:  return `№${this.documentInfo.Document.Card.RegNumber}`;
                case 2:  return this.$t('Б/Р');
                default: return !this.documentInfo.Document.is_new_record && this.documentInfo.Document.Card.DraftNumber ? `№${this.documentInfo.Document.Card.DraftNumber}` : '';
            }
        },
        docDate()
        {
            if(!this.documentInfo.Document)
            {
                if (this.documentInfo.__type == 'Order:#Avrora.Objects.Modules.Docflow.DocflowObjects')
                    return this.documentInfo.ResolutionDate ? `${this.$t('от_время_value.message', { value: this.formatDate(this.documentInfo.ResolutionDate, 'DD.MM.YYYY') })}` : '';

                if (this.documentInfo.__type == 'Meeting:#Avrora.Objects.Modules.Meetings' && !this.documentInfo.is_new_record)
                    return this.documentInfo.RecDate ? `${this.$t('на_время_value.message', { value: this.formatDate(this.documentInfo.Date, 'DD.MM.YYYY') })}` : '';
            
                return '';
            }

            return this.documentInfo.Document.RegState != 1
                ? this.documentInfo.Document.CreateDate ? `${this.$t('от_время_value.message', { value: this.formatDate(this.documentInfo.Document.CreateDate, 'DD.MM.YYYY') })}` : ''
                : this.documentInfo.Document.Card.RegDate ? `${this.$t('от_время_value.message', { value: this.formatDate(this.documentInfo.Document.Card.RegDate, 'DD.MM.YYYY') })}` : '';
        },
        docVersion()
        {
            if (!this.documentInfo.Document)
                return '';

            if (!['OutgoingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects', 'InnerDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects'].includes(this.documentInfo.Document.__type))
                return '';

            return this.documentInfo.Document.RegState != 1
                ? this.documentInfo.Document.is_new_record ? '' : (this.documentInfo.Document.Version ? `, ${this.$t('Версия')} ${this.documentInfo.Document.Version}` : '')
                : '';
        },
        documentStatusText() 
        {
            if (this.documentInfo.__type == 'Meeting:#Avrora.Objects.Modules.Meetings'){
                if (this.documentInfo.is_new_record)
                    return this.$t('Новое');
                if (this.documentInfo.Status == 1)
                    return this.$t('Отменено');
                switch(this.documentInfo.WorkStatus){
                    case 1: return this.$t('Запланировано');
                    case 2: return this.$t('Проводится');
                    case 3: return this.$t('Завершено');
                    default: return '';
                }
            }
            if(!this.documentInfo.Document)
                return '';

            if (this.documentInfo.Document.ArchYear)
                return `${this.$t('Статус_документа')}: ${this.$t('Архивный')}`;

            if (this.documentInfo.Document.RegState != 1 || ['IQalaStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects', 'Contract:#Avrora.Objects.Modules.Docflow.DocflowObjects.C4'].includes(this.documentInfo.Document.__type))
            {
                let statusText;
                switch (this.documentInfo.Status)
                {
                    case 0: statusText = this.$t('Новый'); break;
                    case 1: statusText = this.$t('На_регистрации'); break;
                    case 2: statusText = this.$t('Отклонен'); break;
                    case 3: statusText = this.$t('Зарегистрирован'); break;
                    case 4: statusText = this.$t('На_рассмотрении'); break;
                    case 5: statusText = this.$t('Рассмотрен'); break;
                    case 6: statusText = this.$t('На_подписи'); break;
                    case 7: statusText = this.$t('Подписан'); break;
                    case 8: statusText = this.$t('Отправлен'); break;
                    case 9: statusText = this.$t('На_согласовании'); break;
                    case 10: statusText = this.$t('Согласован'); break;
                    case 11: statusText = this.$t('На_исполнении'); break;
                    case 12: statusText = this.$t('Исполнено'); break;
                    case 13: statusText = this.$t('Завершен'); break;
                    case 14: statusText = this.$t('Удален'); break;

                    case 15: statusText = this.$t('Контроль'); break;
                    case 17: statusText = this.$t('Особый_контроль'); break;
                    case 18: statusText = this.$t('Снят_с_контроля'); break;

                    case 19: statusText = this.$t('На_доработке'); break;
                    case 20: statusText = this.$t('Принят'); break;
                    case 21: statusText = this.$t('Ожидание_оплаты'); break;
                    case 22: statusText = this.$t('Оплата_произведена'); break;
                    case 23: statusText = this.$t('На_подписании_у_заявителя'); break;                    
                    case 24: statusText = this.$t('На_подписании_у_услугодателя'); break;
                    case 25: statusText = this.$t('Отказ'); break;
                    case 26: statusText = this.$t('Подписан_заявителем'); break;
                    case 27: statusText = this.$t('Заключен'); break;

                    default: statusText = ''; break;
                }

                if (statusText === '')
                {
                    return '';
                }
                else
                {
                    switch(this.documentInfo.Document.__type)
                    {
                        case 'IQalaStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects': return `${this.$t('Статус_заявки')}: ${statusText}`;
                        case 'Contract:#Avrora.Objects.Modules.Docflow.DocflowObjects.C4': return `${this.$t('Статус_договора')}: ${statusText}`;

                        default: return `${this.$t('Статус_документа')}: ${statusText}`;

                    }                        
                }
            }
            else
            {
                if (['OutgoingDocumentDSP:#Avrora.Objects.Modules.Docflow.DocflowObjects', 'IncomingDocumentDSP:#Avrora.Objects.Modules.Docflow.DocflowObjects'].includes(this.documentInfo.Document.__type)) {
                    let statusText;
                    
                    switch (this.documentInfo.Status)
                    {
                        case 14: statusText = this.$t('Удален'); break;
                        default: break;
                    }

                    return statusText ? `${this.$t('Статус_документа')}: ${statusText}` : '';
                }

                return '';
            }
        }
    },
    methods:{
        formatDate: sys.dateFormat,
    }
}
</script>